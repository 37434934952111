import React from "react";

import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { Conditions } from "@pages/clientsOffers/components/conditions/Conditions";
import AccessCode from "@src/pages/clientsOffers/components/AccessCode";
import ExpirationDate from "@src/pages/clientsOffers/components/ExpirationDate";
import OpeningNotification from "@src/pages/clientsOffers/components/OpeningNotification";
import QuoteNotification from "@src/pages/clientsOffers/components/QuoteNotification";
import { QuoteLayout } from "@src/pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/QuoteLayout";
import { clientsOffersSelectedIdSelector } from "@src/recoil/clientOffers";

import {
	OfferParametersTitle,
	Wrapper,
	WrapperAccess,
	WrapperConditions,
	WrapperNotification,
	WrapperQuote,
} from "./OfferParameters.style";

export const OfferParameters = () => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	return (
		<Wrapper>
			<WrapperNotification>
				<OfferParametersTitle>{t("Notification settings")}</OfferParametersTitle>
				<OpeningNotification />
				<QuoteNotification />
			</WrapperNotification>
			<WrapperAccess>
				<OfferParametersTitle>{t("Accessibility settings")}</OfferParametersTitle>
				<ExpirationDate />
				<AccessCode />
			</WrapperAccess>
			<WrapperQuote>
				<OfferParametersTitle>{t("Purchase order settings")}</OfferParametersTitle>
				<QuoteLayout isOfferParameters offerId={offerId} />
			</WrapperQuote>
			<WrapperConditions>
				<OfferParametersTitle>
					{t("General and particular conditions")}
				</OfferParametersTitle>
				<Conditions />
			</WrapperConditions>
		</Wrapper>
	);
};
OfferParameters.whyDidYouRender = true;
OfferParameters.displayName = "OfferParameters";
export default OfferParameters;
