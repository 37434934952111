import { setContext } from "@apollo/client/link/context";
import { ApolloClient, ApolloLink, from, split } from "@apollo/client";
import { cache } from "@src/cache/cache";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import openReplayTracker from "@config/openReplay";
import { onError } from "@apollo/client/link/error";
import { message } from "priceit-ui";
import { createGraphqlMiddleware } from "@openreplay/tracker-graphql";

export const authLink = (sessionId, workspaceId) =>
	setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		const token = localStorage.getItem("jwtToken");
		// return the headers to the context so httpLink can read them
		const newHeaders = {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
			"X-OpenReplay-sessionid": openReplayTracker.getSessionID(),
			"X-OpenReplay-SessionURL": openReplayTracker.getSessionURL(),
		};
		if (sessionId) {
			newHeaders.sessionId = sessionId;
		}
		if (workspaceId && !newHeaders.workspaceId) {
			newHeaders.workspaceId = workspaceId;
		}
		return {
			headers: newHeaders,
		};
	});

export const uploadLink = createUploadLink({
	uri: process.env.API_URL + "/graphql",
	credentials: "include",
	headers: {
		"Apollo-Require-Preflight": "true",
	},
});

const wsLinkConstructor = (sessionId, workspaceId) => {
	return new GraphQLWsLink(
		createClient({
			url: process.env.WS_URI,
			connectionParams: {
				sessionId,
				workspaceId,
			},
			retryAttempts: 5,
			shouldRetry: () => {
				return true;
			},
		})
	);
};

const sanitizeVariables = variables => {
	if (!variables) {
		return variables;
	}
	const newVariables = {};
	Object.keys(variables).forEach(key => {
		if (typeof variables[key] === "object" && variables[key] !== null) {
			newVariables[key] = sanitizeVariables(variables[key]);
		} else if (["password", "code", "email"].includes(key)) {
			newVariables[key] = "*******";
		} else {
			newVariables[key] = variables[key];
		}
	});
	return newVariables;
};

const handler = openReplayTracker.use(createGraphqlMiddleware());

const trackerApolloLink = new ApolloLink((operation, forward) => {
	operation.setContext({ start: performance.now() });
	return forward(operation).map(result => {
		const operationDefinition = operation.query.definitions[0];
		const time = performance.now() - operation.getContext().start;
		return handler(
			operationDefinition.kind === "OperationDefinition"
				? operationDefinition.operation
				: "unknown?",
			operation.operationName,
			sanitizeVariables(operation.variables),
			result,
			time
		);
	});
});

let hasShownToast = false;
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message: msg, locations, path, extensions }) => {
			if (
				(msg === "Insufficient permissions" ||
					extensions?.code === "INSUFFICIENT_PERMISSIONS") &&
				!path.includes("verifyUserAndWorkspaceAccess")
			) {
				message.error(msg);
			}
			console.error(
				`[GraphQL error]: Message: ${msg}, Location: ${locations}, Path: ${path}`
			);
		});

		const userNotAuthenticatedError = graphQLErrors.find(
			({ message: msg }) => msg === "User not authenticated"
		);

		if (userNotAuthenticatedError) {
			const location = window.location.pathname;
			if (location === "/login" || location === "/welcome" || hasShownToast) {
				return;
			}
			if (location === "/login") {
				if (!localStorage.getItem("firstVisit")) {
					localStorage.setItem("firstVisit", "true");
				}
			}
			if (
				(!localStorage.getItem("firstVisit") && location === "/login") ||
				location === "/"
			) {
				return;
			}

			message.error(userNotAuthenticatedError.message);

			hasShownToast = true;
			setTimeout(() => {
				window.location.href = "/login";
				localStorage.setItem("redirect", location);
			}, 1500);
		}
	}
	if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const client = (sessionId = null, workspaceId = null) => {
	const splitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === "OperationDefinition" && definition.operation === "subscription"
			);
		},
		wsLinkConstructor(sessionId, workspaceId),
		from([trackerApolloLink, errorLink, authLink(sessionId, workspaceId).concat(uploadLink)])
	);

	return new ApolloClient({
		cache,
		defaultOptions: {
			query: {
				fetchPolicy: "cache-first",
				errorPolicy: "all",
			},
			mutate: {
				errorPolicy: "all",
			},
		},
		connectToDevTools: process.env.NODE_ENV !== "production",
		link: splitLink,
		// credentials: 'include'
	});
};
