import React, { memo } from "react";
import * as S from "./TimeTable.style";
import { updateUpdateAtInCacheForLinks } from "@pages/myPricingTools/buildingTools/priceModels/overview/links/drawer/drawerLinksService";
import { Checkbox, Tooltipv3 } from "priceit-ui";
import { StyledTextCheckbox } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/CombinationModal.style";
import { updateQuoteConfigurationShowPaymentScheduleMutation } from "@graphQl/mutations/quoteConfiguration";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";
import { useQuoteConfigurationData } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/hooks/useQuoteConfigurationData";
import { StyledCheckboxGroup } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/QuoteLayout.style";
import { useRecoilValue } from "recoil";
import { priceModelPageRefElAtom } from "@src/recoil/priceModel/atom";

const TimeTable = memo(({ offerId, priceModelCombinationId }) => {
	const { t } = useTranslation("specific/priceModel");
	const { mode } = useThemeContext();
	const priceModelPageEl = useRecoilValue(priceModelPageRefElAtom);

	const { quoteConfigurationId, showPaymentSchedule } = useQuoteConfigurationData({
		offerId,
		priceModelCombinationId,
	});

	const [updateQuoteConfigurationShowPaymentSchedule] =
		updateQuoteConfigurationShowPaymentScheduleMutation();

	return (
		<S.Wrapper>
			<StyledCheckboxGroup
				checkIcon
				focusColor="#FFCD38"
				boxLabelDistance="0px"
				interBoxDistance="8px"
				style={{ marginLeft: "-7.5px" }}
				selected={showPaymentSchedule ? ["showPaymentSchedule"] : []}
				onChange={() => {
					updateQuoteConfigurationShowPaymentSchedule({
						variables: {
							updateQuoteConfiguration: {
								id: quoteConfigurationId,
								showPaymentSchedule: !showPaymentSchedule,
							},
						},
						optimisticResponse: {
							updateQuoteConfiguration: {
								__typename: "QuoteConfiguration",
								id: quoteConfigurationId,
								showPaymentSchedule: !showPaymentSchedule,
							},
						},
						update(cache) {
							priceModelCombinationId !== null &&
								updateUpdateAtInCacheForLinks(cache, priceModelCombinationId);
						},
					});
				}}
			>
				<Tooltipv3
					key={"timetable"}
					placement="top"
					mode={mode}
					type="primary"
					maxWidth="350px"
					content={t(
						"A table showing details of the payments to be made according to the billing period will be added on the last page of the quote."
					)}
					interactive={false}
					appendTo={priceModelPageEl || document.body}
				>
					<Checkbox.Button
						mode={mode}
						value={"showPaymentSchedule"}
						width="15px"
						height="15px"
					>
						<StyledTextCheckbox>{t("Timetable")}</StyledTextCheckbox>
					</Checkbox.Button>
				</Tooltipv3>
			</StyledCheckboxGroup>
		</S.Wrapper>
	);
});

TimeTable.displayName = "TimeTable";

export default TimeTable;
