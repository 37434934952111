import React from "react";
import { RowWrapper } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/generalSettings/components/brandingAndDesign/BrandingAndDesign.style";

import { WrapperCheckboxesAndTitle } from "../../quoteHeader/QuoteHeader.style";
import IntroText from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/components/introText/IntroText";
import TotalBoxes from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/components/totalBoxes/TotalBoxes";
import VatDetail from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/components/vatDetail/VatDetail";
import AttributesDescriptions from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/components/attributesDescriptions/AttributesDescriptions";
import TimeTable from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/components/timeTable/TimeTable";
import IncludedMention from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/components/includedMention/IncludedMention";

export const QuoteLayout = React.memo(({ isOfferParameters, offerId, priceModelCombinationId }) => {
	return (
		<RowWrapper>
			<WrapperCheckboxesAndTitle>
				<IntroText offerId={offerId} priceModelCombinationId={priceModelCombinationId} />
				<TotalBoxes
					offerId={offerId}
					priceModelCombinationId={priceModelCombinationId}
					isOfferParameters={isOfferParameters}
				/>
				<VatDetail offerId={offerId} priceModelCombinationId={priceModelCombinationId} />
				<AttributesDescriptions
					offerId={offerId}
					priceModelCombinationId={priceModelCombinationId}
				/>
				<TimeTable offerId={offerId} priceModelCombinationId={priceModelCombinationId} />
				<IncludedMention
					offerId={offerId}
					priceModelCombinationId={priceModelCombinationId}
				/>
			</WrapperCheckboxesAndTitle>
		</RowWrapper>
	);
});

export default QuoteLayout;
QuoteLayout.displayName = "QuoteLayout";
