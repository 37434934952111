import React, { memo } from "react";
import * as S from "./IntroText.style";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";
import { useQuoteConfigurationData } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/hooks/useQuoteConfigurationData";
import { Checkbox, TextArea, Tooltipv3 } from "priceit-ui";
import { updateUpdateAtInCacheForLinks } from "@pages/myPricingTools/buildingTools/priceModels/overview/links/drawer/drawerLinksService";
import { StyledTextCheckbox } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/CombinationModal.style";
import {
	updateQuoteConfigurationIntroductionTextMutation,
	updateQuoteConfigurationShowIntroductionTextMutation,
	updateQuoteConfigurationUseIntroTextFromIntroPageMutation,
} from "@graphQl/mutations/quoteConfiguration";
import { MAX_LENGTH_INTRO_TEXT_QUOTE_CONFIG } from "@services/constants";
import { StyledCheckboxGroup } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteHeader/components/QuoteLayout.style";
import { useRecoilValue } from "recoil";
import { priceModelPageRefElAtom } from "@src/recoil/priceModel/atom";

const IntroText = memo(({ offerId, priceModelCombinationId }) => {
	const { t } = useTranslation("specific/priceModel");
	const { mode } = useThemeContext();
	const priceModelPageEl = useRecoilValue(priceModelPageRefElAtom);

	const {
		quoteConfigurationId,
		showIntroductionText,
		introductionText,
		useIntroTextFromIntroPage,
	} = useQuoteConfigurationData({
		offerId,
		priceModelCombinationId,
	});

	const [updateQuoteConfigurationShowIntroductionText] =
		updateQuoteConfigurationShowIntroductionTextMutation();
	const [updateQuoteConfigurationUseIntroTextFromIntroPage] =
		updateQuoteConfigurationUseIntroTextFromIntroPageMutation();
	const [updateQuoteConfigurationIntroductionText] =
		updateQuoteConfigurationIntroductionTextMutation();

	return (
		<S.Wrapper>
			<StyledCheckboxGroup
				checkIcon
				focusColor="#FFCD38"
				boxLabelDistance="0px"
				interBoxDistance="8px"
				style={{ marginLeft: "-7.5px" }}
				selected={showIntroductionText ? ["showIntroductionText"] : []}
				onChange={() => {
					updateQuoteConfigurationShowIntroductionText({
						variables: {
							updateQuoteConfiguration: {
								id: quoteConfigurationId,
								showIntroductionText: !showIntroductionText,
							},
						},
						optimisticResponse: {
							updateQuoteConfiguration: {
								__typename: "QuoteConfiguration",
								id: quoteConfigurationId,
								showIntroductionText: !showIntroductionText,
							},
						},
						update(cache) {
							priceModelCombinationId !== null &&
								updateUpdateAtInCacheForLinks(cache, priceModelCombinationId);
						},
					});
				}}
			>
				<Tooltipv3
					key={"introText"}
					placement="top"
					mode={mode}
					type="primary"
					maxWidth="350px"
					content={t(
						"This text will be displayed on the first page of your PDF, between the title and the signature."
					)}
					interactive={false}
					appendTo={priceModelPageEl || document.body}
				>
					<Checkbox.Button
						mode={mode}
						value={"showIntroductionText"}
						width="15px"
						height="15px"
					>
						<StyledTextCheckbox>{t("Quote introduction text PDF")}</StyledTextCheckbox>
					</Checkbox.Button>
				</Tooltipv3>
			</StyledCheckboxGroup>
			{showIntroductionText && (
				<S.WrapperSwitchAndTextArea>
					<S.WrapperSwitchAndCounter>
						<S.StyledToggleSwitch
							optionLeft={
								<S.LabelIntroTextSwitch>
									{t("Use the text on the introduction page")}
								</S.LabelIntroTextSwitch>
							}
							checked={useIntroTextFromIntroPage}
							onClick={() =>
								updateQuoteConfigurationUseIntroTextFromIntroPage({
									variables: {
										updateQuoteConfiguration: {
											id: quoteConfigurationId,
											useIntroTextFromIntroPage: !useIntroTextFromIntroPage,
										},
									},
									optimisticResponse: {
										updateQuoteConfiguration: {
											__typename: "QuoteConfiguration",
											id: quoteConfigurationId,
											useIntroTextFromIntroPage: !useIntroTextFromIntroPage,
										},
									},
									update(cache) {
										priceModelCombinationId !== null &&
											updateUpdateAtInCacheForLinks(
												cache,
												priceModelCombinationId
											);
									},
								})
							}
							type="outer"
						/>
						{!useIntroTextFromIntroPage && (
							<S.Counter>
								{introductionText?.length ?? 0}/{MAX_LENGTH_INTRO_TEXT_QUOTE_CONFIG}
							</S.Counter>
						)}
					</S.WrapperSwitchAndCounter>
					{!useIntroTextFromIntroPage && (
						<S.TextAreaWrapper>
							<TextArea
								maxLength={MAX_LENGTH_INTRO_TEXT_QUOTE_CONFIG}
								mode={mode}
								value={introductionText}
								width={"100%"}
								noCenter
								tooltipMaxWidth={"445px"}
								maxHeight={"300px"}
								height={"100px"}
								enableDelay={true}
								delay={1000}
								placeholder={t("Introductory text")}
								onChange={e =>
									updateQuoteConfigurationIntroductionText({
										variables: {
											updateQuoteConfiguration: {
												id: quoteConfigurationId,
												introductionText: e.target.value,
											},
										},
										optimisticResponse: {
											updateQuoteConfiguration: {
												__typename: "QuoteConfiguration",
												id: quoteConfigurationId,
												introductionText: e.target.value,
											},
										},
										update(cache) {
											priceModelCombinationId !== null &&
												updateUpdateAtInCacheForLinks(
													cache,
													priceModelCombinationId
												);
										},
									})
								}
							/>
						</S.TextAreaWrapper>
					)}
				</S.WrapperSwitchAndTextArea>
			)}
		</S.Wrapper>
	);
});

IntroText.displayName = "IntroText";
export default IntroText;
